// ENGLISH

app_lng['en'] = { //declared in layout
    // php uploader errors
    e_oops: "Oops",
    e_upload_1: "No file uploaded.",
    e_upload_2: "The file is of zero length.",
    e_upload_3: "The file has to be jpeg, jpg or png.",
    e_upload_4: "The file has to be jpeg, jpg, png or pdf.",
    e_upload_5: "You may be attempting to hack our server. We're on to you; expect a knock on the door sometime soon.",
    e_upload_6: "Error moving uploaded file. Check the script is granted Read/Write/Modify permissions.",
    e_upload_7: "The file is too big. (Max: 4 MB)",

    e_upload_i: "Please select a valid image file",

    e_wrong_format: "<p id='error'>Please Select A valid Image File</p><h4>Note</h4><span id='error_message'>Only jpeg, jpg and png Images type allowed</span>",

    yes: "Yes",
    no: "No",
    m_cancel: "Cancel",
    m_delete: "Delete",

    placeholder: "Type here...",
};