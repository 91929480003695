$(document).ready(function() {
//
//DATA table
    $('.dataTables').DataTable({
        "processing": true,
        "language": {
            "url": "/js/plugins/datatables/lang/"+current_locale+".json"
        },
        "order": [[ 0, "asc" ]],
        "lengthMenu": [50, 100]
    });

    $('.dataTables-largeData').DataTable({
        dom: 'Blfrtip',
        buttons: [
            'csvHtml5',
            {
                extend: 'print',
                customize: function ( win ) {
                    var style = $('<style>.print_color { display: block; }</style>')
                    $(win.document.head).append(style);
                }
            }

        ],
        "scrollX": true,
        "processing": true,
        "lengthMenu": [50, 100],
        "language": {
            "url": "/js/plugins/datatables/lang/"+current_locale+".json"
        },
        "order": [[ 0, "asc" ]]
    });
// form
// form
    // remove "has-error" after add text (not full test)
    $("div.required").change(function () {
        if ($(this).find('input').length > 0) {
            if ($(this).find('input').first().val().length > 0) {
                $(this).removeClass('has-error');
            } else {
                $(this).addClass('has-error');
            }
        }
    });

    // remove "has-warning" after add text (not full test)
    $("div.has-warning").change(function () {
        if ($(this).find('input').first().val().length > 0) {
            $(this).removeClass('has-warning');
        }
    });

// multiselect
    $(".multi-select").chosen();

// chose selector
// chose selector

    //select all
    /*
    $('#select_all_holds>button').click(function(){
        $('#select_all_holds>option').prop('selected', true);
        $('#select_all_holds>.multi-select').trigger('liszt:updated');
    });*/

    $(".chosen-select").chosen();

    //uploader file
    $('#upload_path').change(function () {
        var file = this.files[0];
        if(file.size > (1048576 * 6)) { //upper upload limit for whole site: 6 MB
            swal({
                title: app_lng[current_locale].e_oops,
                text: app_lng[current_locale].e_upload_7,
                allowOutsideClick: true,
                type: "error"
            });
            return false;
        }
        sendFileToServer(file, '0', function (i) {
            $('#path').val(i);
        }, function (e) {
            var error_string = 'e_upload_' + e.toString();
            swal({
                title: app_lng[current_locale].e_oops,
                text: app_lng[current_locale][error_string],
                allowOutsideClick: true,
                type: "error"
            });
        }, 'file_upload', 'file_upload_progress');
    });

    //uploader cases
    $('#upload_image').change(function () {
        var file = this.files[0];
        if(file.size > (1048576 * 4)) { //upper upload limit for whole site: 4 MB
            swal({
                title: app_lng[current_locale].e_oops,
                text: app_lng[current_locale].e_upload_7,
                allowOutsideClick: true,
                type: "error"
            });
            return false;
        }
        var imagefile = file.type;
        var match = ["image/jpeg", "image/png", "image/jpg"];
        if (!((imagefile == match[0]) || (imagefile == match[1]) || (imagefile == match[2]))) {
            swal({
                title: app_lng[current_locale].e_upload_i,
                text: app_lng[current_locale].e_upload_3,
                allowOutsideClick: true,
                type: "error"
            });
            $('progress#file_upload').hide();
            $('#file_upload_progress').hide();
            return false;
        } else {
            sendFileToServer(file, '0', function (i) {
                    $('#image').val(i);
                }, function (e) {
                    var error_string = 'e_upload_' + e.toString();
                    swal({
                        title: app_lng[current_locale].e_oops,
                        text: app_lng[current_locale][error_string],
                        allowOutsideClick: true,
                        type: "error"
                    });
                }, 'file_upload', 'file_upload_progress');
        }


    });


    $('#upload_profile').change(function () {
        var file = this.files[0];
        if(file.size > (1048576 * 4)) { //upper upload limit: 4 MB
            swal({
                title: app_lng[current_locale].e_oops,
                text: app_lng[current_locale].e_upload_7,
                allowOutsideClick: true,
                type: "error"
            });
            return false;
        }
        var imagefile = file.type;
        var match = ["image/jpeg", "image/png", "image/jpg"];
        if (!((imagefile == match[0]) || (imagefile == match[1]) || (imagefile == match[2]))) {
            swal({
                title: app_lng[current_locale].e_upload_i,
                text: app_lng[current_locale].e_upload_3,
                allowOutsideClick: true,
                type: "error"
            });
            $('progress#file_upload').hide();
            $('#file_upload_progress').hide();
            return false;
        } else {
            sendFileToServer(file, $('#user_id_hidden').val(), function (i) {
                $('#image').val(i);
            }, function (e) {
                var error_string = 'e_upload_' + e.toString();
                swal({
                    title: app_lng[current_locale].e_oops,
                    text: app_lng[current_locale][error_string],
                    allowOutsideClick: true,
                    type: "error"
                });
            }, 'file_upload', 'file_upload_progress');
        }


    });

    $('.summernote').summernote({
        toolbar: [
            // [groupName, [list of button]]
            ['basic', ['bold', 'italic', 'underline', 'strikethrough', 'clear']],
            ['font', ['fontname', 'fontsize', 'color']],
            ['para', ['ul', 'ol', 'paragraph']],
            ['insert', ['picture', 'link', 'video', 'table']],
            ['misc', ['undo', 'redo', 'codeview', 'fullscreen']]
        ],
        placeholder: app_lng[current_locale].placeholder,
        disableDragAndDrop: true,
        callbacks: {
            onImageUpload: function (files) {
                $('.summernote').before('<progress id="summernote_progress"></progress>');
                sendFile(files[0], $(this));
            }
        }
    });

    $('.summernote-simple').summernote({
        toolbar: [
            // [groupName, [list of button]]
            ['basic', ['bold', 'italic', 'underline', 'strikethrough']],
            ['font', ['fontsize', 'color']],
            ['para', ['ul', 'ol']],
            ['insert', ['picture', 'link']]
        ],
        placeholder: app_lng[current_locale].placeholder,
        disableDragAndDrop: true,
        callbacks: {
            onImageUpload: function (files) {
                $('.summernote-simple').before('<progress id="summernote_progress"></progress>');
                sendFile(files[0], $(this));
            }
        }
    });

    //DATES

    $(".date-birthday").datepicker({
        format: 'd M yyyy',
        endDate: '-48m',
        startDate: '-100y',
        autoclose: true,
        todayBtn: true,
        todayHighlight: true,
        language: current_locale,
    }).on('hide', function (e) {
        if(e.date) {
            var dateString = e.date.getFullYear().toString() + '-' + two_days_digit(e.date.getMonth() + 1) + '-' + two_days_digit(e.date.getDate());
            $('.real-date-birthday').attr('value', dateString);
        }
    }).each(function() {
        if ($(this).val() != '0000-00-00') {
            var d = new Date($(this).val());
            var dateString = d.getDate().toString() + ' ' + $.fn.datepicker.dates[current_locale].monthsShort[d.getMonth()] + ' ' + d.getFullYear().toString();
            $(this).val(dateString);
        }
    });

    $("#date-valid").datepicker({
        format: 'd M yyyy',
        endDate: '+72m',
        startDate: '-48m',
        autoclose: true,
        todayBtn: true,
        todayHighlight: true,
        language: current_locale,
    }).on('hide', function (e) {
        var dateString = e.date.getFullYear().toString() + '-' + two_days_digit(e.date.getMonth() + 1) + '-' + two_days_digit(e.date.getDate());
        $('#real-date-valid').attr('value', dateString);
    }).each(function() {
        if ($(this).val() != '0000-00-00') {
            var d = new Date($(this).val());
            var dateString = d.getDate().toString() + ' ' + $.fn.datepicker.dates[current_locale].monthsShort[d.getMonth()] + ' ' + d.getFullYear().toString();
            $(this).val(dateString);
        }
    });

    $("#date-valid-past").datepicker({
        format: 'd M yyyy',
        endDate: '+0d',
        startDate: '-48m',
        autoclose: true,
        todayBtn: true,
        todayHighlight: true,
        language: current_locale,
    }).on('hide', function (e) {
        console.log(e);
        var dateString = e.date.getFullYear().toString() + '-' + two_days_digit(e.date.getMonth() + 1) + '-' + two_days_digit(e.date.getDate());
        $('#real-date-valid-past').attr('value', dateString);
    }).each(function() {
        if ($(this).val() != '0000-00-00') {
            var d = new Date($(this).val());
            var dateString = d.getDate().toString() + ' ' + $.fn.datepicker.dates[current_locale].monthsShort[d.getMonth()] + ' ' + d.getFullYear().toString();
            $(this).val(dateString);
        }
    });


    //start-end
    $("#end-field").datepicker({
        format: 'd M yyyy',
        autoclose: true,
        todayBtn: true,
        todayHighlight: true,
        language: current_locale,
    }).on('hide', function (e) {
        var dateString = e.date.getFullYear().toString() + '-' + two_days_digit(e.date.getMonth() + 1) + '-' + two_days_digit(e.date.getDate());
        $('#real-end-date').attr('value', dateString);
    }).each(function() {
        if ($(this).val() != '0000-00-00') {
            var d = new Date($(this).val());
            var dateString = d.getDate().toString() + ' ' + $.fn.datepicker.dates[current_locale].monthsShort[d.getMonth()] + ' ' + d.getFullYear().toString();
            $(this).val(dateString);
        }
    });

    $("#start-field").datepicker({
        format: 'd M yyyy',
        autoclose: true,
        todayBtn: true,
        todayHighlight: true,
        language: current_locale,
    }).on('hide', function (e) {
        var dateString = e.date.getFullYear().toString() + '-' + two_days_digit(e.date.getMonth() + 1) + '-' + two_days_digit(e.date.getDate());
        $('#real-start-date').attr('value', dateString);
    }).each(function() {
        if ($(this).val() != '0000-00-00') {
            var d = new Date($(this).val());
            var dateString = d.getDate().toString() + ' ' + $.fn.datepicker.dates[current_locale].monthsShort[d.getMonth()] + ' ' + d.getFullYear().toString();
            $(this).val(dateString);
        }
    });

    /*.on('changeDate', function(e) {
        var end = $("#end-field");
        var end_date = end.datepicker('getDate');
        alert(end_date+' '+ e.date);
        if(end_date >= e.date) {
            end.datepicker('setStartDate', $(this).val());
        } else if(end_date < e.date) {
            end.datepicker('setDate', $(this).val());
            end.datepicker('setStartDate', $(this).val());
            //update fields
            var dateString = e.date.getFullYear().toString() + '-' + two_days_digit(e.date.getMonth() + 1) + '-' + two_days_digit(e.date.getDate());
            $('#real-end-date').attr('value', dateString);
            $('#end-field').attr('value', dateString);
        }
    })*/

//NOTIFICATION TEXTAREA
    $('#notification_limit textarea').keyup(function() {
        var text_length = $(this).val().length;
        var text_remaining = parseInt($(this).attr('maxlength')) - text_length;

        if(text_remaining < 10 && text_remaining > 0) {
            $('#remaining_char').removeClass('text-danger').addClass('text-warning');
        } else if(text_remaining == 0) {
            $('#remaining_char').removeClass('text-warning').addClass('text-danger');
        } else {
            $('#remaining_char').removeClass('text-warning', 'text-danger');
        }

        $('#remaining_char').html(text_remaining);
    });

//NICE CHECKBOX
    $('.i-checks').iCheck({
        checkboxClass: 'icheckbox_square-green',
        radioClass: 'iradio_square-green',
    });

//CLOCK
    $('.clockpicker').clockpicker({
        autoclose: true
    });

//Fullcalendar
//Fullcalendar
    $('#fullCalendar').fullCalendar({
        header: {
            left: 'prev,next today',
            center: 'title',
            right: 'month,agendaWeek'
        },
        weekNumbers: true,
        aspectRatio: 2,
        fixedWeekCount: false,
        eventSources: [
            {
                url: "events"
            }
        ],
        eventRender: function (event, element) {
            element.attr('title', event.tooltip);
            element.tooltipster({
                contentAsHTML: true
            });
        },
        loading: function (bool) {
            if (!bool) {
                $('#calendar-loading').css("display","none");
            } else {
                $('#calendar-loading').css("display","block");
            }
        },
        eventClick: function(event) {
            if (event.showUrl) {
                window.open(event.showUrl, '_self');
                return false;
            }
        }
    });

//search
//serach
    $("#top-search").keyup(function () {
        var filter = $(this).val();
        var count = 0;

        $(".searchable").each(function () {
            var parent = $(this).parent(), length = $(this).text().length > 0;

            if (length && $(this).text().search(new RegExp(filter, "i")) < 0) {
                parent.hide();
            } else {
                parent.show();
                count++;
            }
        });
        //$("#filter-count").text(count);
        if (filter.length > 0) {
            $('.search-bar .cancel').removeClass('hidden');
        } else {
            $('.search-bar .cancel').addClass('hidden');
        }

        if (count == 0) {
            $(this).addClass('text-danger');
            $('.search-bar .icon').addClass('text-danger');
        } else {
            $(this).removeClass('text-danger');
            $('.search-bar .icon').removeClass('text-danger');
        }
    });

    $('.search-bar .cancel').click(function () {
        $("#top-search").val('');
        $('.search-bar .cancel').addClass('hidden');
        $(".searchable").each(function () {
            var parent = $(this).parent();
            parent.show();
        });
    });

    //DATA CONFIRM
    $('form[data-confirm] [type=submit]').on('click', function(ev) {
        ev.preventDefault();
        var parent = $(this).closest('form[data-confirm]');
        var thisForm = parent;
        if (parent.attr('data-success') !== undefined) {
            var style = 'success';
            var btn_text = parent.attr('data-success');
        } else {
            var style = 'danger';
            var btn_text = app_lng[current_locale].m_delete;
        }
        if (!$('#dataConfirmModal').length) {
            $('body').append('' +
                '<div id="dataConfirmModal" class="modal" role="dialog" aria-labelledby="dataConfirmLabel" aria-hidden="true">' +
                '<div class="modal-dialog"><div class="modal-content">' +
                '<div class="modal-header">' +
                '<button type="button" class="close" data-dismiss="modal" aria-hidden="true">×</button>' +
                '<h3 id="dataConfirmLabel">' + parent.attr('data-title') + '</h3>' +
                '</div>' +
                '<div class="modal-body"></div>' +
                '<div class="modal-footer">' +
                '<button class="btn" data-dismiss="modal" aria-hidden="true">' + app_lng[current_locale].m_cancel + '</button>' +
                '<a class="btn btn-' + style + '" id="dataConfirmOK">' + btn_text + '</a>' +
                '</div>' +
                '</div></div>' +
                '</div>');
        }
        $('#dataConfirmModal').find('.modal-body').text(parent.attr('data-confirm'));
        $('#dataConfirmOK').bind( "click", function() {
            thisForm.submit();
        });
        $('#dataConfirmModal').modal({show:true});
        return false;
    });


});

//sidebar
//sidebar
$(document).mouseup(function (e)
{
    var sidebar = $('#right-sidebar.sidebar-open');

    if (!sidebar.is(e.target) && sidebar.has(e.target).length === 0) {
        if(sidebar.hasClass('sidebar-open')) {
            sidebar.animate({
                right: "-260"
            }, 200, function() {sidebar.removeClass('sidebar-open');});
        } else {
            sidebar.animate({
                right: "0"
            }, 200, function() {sidebar.addClass('sidebar-open');});
        }
    }
});


//toaster settings
toastr.options = {
    "closeButton": true,
    "debug": false,
    "progressBar": true,
    "preventDuplicates": false,
    "positionClass": "toast-top-right",
    "onclick": null,
    "showDuration": "400",
    "hideDuration": "1000",
    "timeOut": "7000",
    "extendedTimeOut": "1000",
    "showEasing": "swing",
    "hideEasing": "linear",
    "showMethod": "fadeIn",
    "hideMethod": "fadeOut"
};
