// GERMAN

app_lng['de'] = { //declared in layout
    // php uploader errors
    e_oops: "Uups",
    e_upload_1: "Keine Datei beigefügt.",
    e_upload_2: "Die Datei ist leer.",
    e_upload_3: "Die Datei muss im jpeg, jpg oder png Format sein.",
    e_upload_4: "Die Datei muss im jpeg, jpg, png oder pdf Format sein.",
    e_upload_5: "Willst du den Server hacken? Erwarte jemanden an deiner Tür.",
    e_upload_6: "Fehler beim bewegen der Datei. Read/Write/Modify Rechte?",
    e_upload_7: "Die Datei ist zu gross. (Max: 4 MB)",

    e_upload_i: "Bitte wähle eine korrekte Bild Datei aus",

    e_wrong_format: "<p id='error'>Bitte wähle eine korrekte Bild Datei aus.</p><h4>Hilfe</h4><span id='error_message'>Nur jpeg, jpg und png Bilder sind erlaubt</span>",

    yes: "Ja",
    no: "Nein",
    m_cancel: "Abbrechen",
    m_delete: "Löschen",

    placeholder: "Schreibe hier rein...",
};